<template>
    <section class="landscape-tab-products-wrap clearfix">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-18 text-center">
                    <div class="scent-area">
                        <h3>Tüm Ürünlerimizi Hassasiyetle Üretiyoruz, <strong>Çünkü Koku Bizim Tutkumuz 🎁 </strong></h3>
                        <p>
                            <strong>bueno's</strong> markalı ürünler; sadece kalitesi ile değil aynı zamanda size özel bir deneyim sunmayı amaçlar. Hayalinizdeki kokuların peşinden gidin, kendinizi özel hissedin ve her anınızı buenos's dokunuşuyla ölümsüzleştirin.
                            <strong>buenos's</strong> tutkulu ve benzersiz kokularla sizi büyüleyen bir dünyaya davet ediyor. 🌟
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="tab-products-header clearfix">
                <ul class="tabs float-right">
                    <li
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :class="classes(tab)"
                        @click="change(tab)"
                    >
                        {{ tab.label }}
                    </li>
                </ul>
            </div>

            <div class="tab-content">
                <div class="landscape-right-tab-products">
                    <ProductCard
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                    />
                </div>
            </div>
        </div>

        <dynamic-tab
            v-for="(tabLabel, index) in data.tabs"
            :key="index"
            :label="tabLabel"
            :url="
                route('storefront.tab_products.index', {
                    sectionNumber: 2,
                    tabNumber: index + 1,
                })
            "
        >
        </dynamic-tab>
    </section>
</template>

<script>
import ProductCard from "../ProductCard.vue";
import DynamicTabsMixin from "../../mixins/DynamicTabsMixin";

export default {
    components: { ProductCard },

    mixins: [DynamicTabsMixin],

    props: ["data"],

    methods: {
        selector() {
            return $(".landscape-right-tab-products");
        },

        slickOptions() {
            return {
                rows: 0,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                rtl: window.FleetCart.rtl,
                responsive: [
                    {
                        breakpoint: 1761,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                        },
                    },
                    {
                        breakpoint: 1301,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                        },
                    },
                    {
                        breakpoint: 1051,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 881,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 641,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                ],
            };
        },
    },
};
</script>
