<template>
    <section class="banner-wrap one-column-banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="propose">
                        <h3 class="h1" aria-level="2">
                            BR BOSPHORUS <br> İSTANBUL İKONİK KOKUSU
                        </h3>
                        <p>İSTANBUL BOĞAZI'NIN ESİNTİSİNİ HİSSEDİN. <br /> BERGAMOT | SİYAH ORKİDE | SANDAL AĞACI</p>
                        <a :href="banner.call_to_action_url" class="btn btn-primary">ALIŞVERİŞE BAŞLA</a>
                    </div>
                </div>
                <div class="col-lg-9">
                    <a
                        :href="banner.call_to_action_url"
                        class="banner"
                        :target="banner.open_in_new_window ? '_blank' : '_self'"
                    >
                        <img class="popularProduct" :src="banner.image.path" alt="Banner" />
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: ["banner"],
};
</script>
